import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance'; // Import the axios instance
import { Grid, Card, CardContent, Typography, Button, TextField, Box } from '@mui/material';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(''); // State to handle display message

  const handleResetPassword = async (event) => {
    event.preventDefault();
    try {
      await axiosInstance.post('/api/user/forgot-password', { email });
      setMessage('If your account exists, a reset link will be sent to your email address.');
    } catch (error) {
      setMessage('Failed to send reset link. Please try again later.');
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="bold" gutterBottom>
              Forgot Password
            </Typography>
            <form onSubmit={handleResetPassword}>
              <TextField
                label="Enter your email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit" fullWidth>
                  Send reset link
                </Button>
              </Box>
              {message && (
                <Box mt={2}>
                  <Typography color="textSecondary" variant="body2">
                    {message}
                  </Typography>
                </Box>
              )}
              <Box mt={2} textAlign="center">
                <Typography variant="body2">
                  Remembered your password? <Link to="/login">Log in</Link>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;