import React, { useState } from 'react';
import { Typography, Button, useTheme, Box, Dialog, CircularProgress } from '@mui/material';
import { Calendar as BigCalendar, dateFnsLocalizer } from 'react-big-calendar';
import CalendarToolbar from './CalendarToolbar';
import PropTypes from 'prop-types';
import FloatingSettingsMenu from './FloatingSettingsMenu';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay } from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import syncIcon from '../assets/sync.png';
import '../App.css';

const locales = {
  'en-US': enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: () => startOfWeek(new Date(), { weekStartsOn: 0 }),
  getDay,
  locales,
});

const LoadingModal = ({ open }) => {
  const [messageIndex, setMessageIndex] = useState(0);
  const messages = [
    "Updating your schedule...",
    "Understanding your calendar...",
    "Feeding the server hamsters..."
  ];

  React.useEffect(() => {
    if (!open) return;

    const interval = setInterval(() => {
      setMessageIndex((prev) => (prev + 1) % messages.length);
    }, 2000);

    return () => clearInterval(interval);
  }, [open]);

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown
      PaperProps={{
        elevation: 0,
        sx: {
          backgroundColor: 'background.paper',
          borderRadius: 2,
          p: 4,
          minWidth: 300,
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 3
        }}
      >
        <CircularProgress size={48} />
        <Typography
          variant="body1"
          sx={{
            textAlign: 'center',
            minWidth: 200,
            color: 'text.primary'
          }}
        >
          {messages[messageIndex]}
        </Typography>
      </Box>
    </Dialog>
  );
};

// Updated Compact Header Component
const CustomHeader = ({ label }) => {
  return (
    <Typography
      component="span"
      sx={{
        fontWeight: 700,
        textTransform: 'uppercase',
        letterSpacing: '0.05em',
        padding: '0.4rem',
        display: 'block',
        fontSize: '0.8rem',
        lineHeight: 1.2,
      }}
    >
      {label}
    </Typography>
  );
};

function Calendar({ events, onSync, calendarType, addCustomEventButton, needsUpdate }) {
  const theme = useTheme();
  const [minTime, setMinTime] = useState(8);
  const [maxTime, setMaxTime] = useState(18);
  const [view, setView] = useState('week');
  const [isLoading, setIsLoading] = useState(false);

  const timeToDate = (hours) => {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  };

  const handleSync = async () => {
    setIsLoading(true);
    try {
      await onSync();
    } finally {
      setIsLoading(false);
    }
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = event.color || theme.palette.primary.main;
    const style = {
      backgroundColor,
      borderRadius: '8px',
      opacity: 0.85,
      color: theme.palette.getContrastText(backgroundColor),
      border: '0px',
      display: 'block',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
      fontSize: '0.85rem',
    };
    return { style };
  };

  // Format the header dates
  const formats = {
    weekdayFormat: (date, culture, localizer) =>
      localizer.format(date, 'EEE d', culture).toUpperCase(),
    dayFormat: (date, culture, localizer) =>
      localizer.format(date, 'EEE d', culture).toUpperCase(),
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: 'calc(100% + 48px)',
        backgroundColor: 'grey.50',
        p: 3,
        position: 'relative',
        margin: -3,
        marginRight: -3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mb: 2,
          flexShrink: 0,
        }}
      >
        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          {needsUpdate && (
            <Typography
              variant="body2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                color: '#363838',
                fontWeight: 500,
              }}
            >
              🚩 Hey! You've got pending project changes to schedule →
            </Typography>
          )}

          <Button
            variant="contained"
            color="primary"
            onClick={handleSync}
            sx={{
              color: '#ffffff !important',
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <img
              src={syncIcon}
              alt="sync icon"
              style={{
                width: 16,
                height: 16,
                filter: 'brightness(0) invert(1)'
              }}
            />
            Update Schedule
          </Button>

          {calendarType === 'ics' && addCustomEventButton}
        </Box>
      </Box>

      <LoadingModal open={isLoading} />

      <Box
        sx={{
          flexGrow: 1,
          minHeight: 0,
          position: 'relative',
          '& .rbc-time-header': {
            minHeight: 'unset',
          },
          '& .rbc-time-header-content': {
            minHeight: 'unset',
          },
          '& .rbc-header': {
            minHeight: 'unset',
            padding: '0.4rem !important',
          },
          '& .rbc-time-view': {
            borderTop: '1px solid #ddd',
          },
          '& .rbc-time-gutter .rbc-timeslot-group': {
            '& .rbc-time-slot': {
              color: '#2D3748',
              fontWeight: 500,
              fontSize: '0.85rem',
            }
          },
        }}
      >
        <BigCalendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          formats={formats}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          view={view}
          views={['month', 'week', 'day']}
          dayLayoutAlgorithm="no-overlap"
          eventPropGetter={eventStyleGetter}
          min={timeToDate(minTime)}
          max={timeToDate(maxTime)}
          onView={setView}
          components={{
            toolbar: CalendarToolbar,
            header: CustomHeader,
          }}
        />
      </Box>

      <FloatingSettingsMenu
        minTime={minTime}
        maxTime={maxTime}
        onMinTimeChange={setMinTime}
        onMaxTimeChange={setMaxTime}
      />
    </Box>
  );
}

Calendar.propTypes = {
  events: PropTypes.array.isRequired,
  onSync: PropTypes.func.isRequired,
  calendarType: PropTypes.string.isRequired,
  addCustomEventButton: PropTypes.node,
  needsUpdate: PropTypes.bool,  // Add prop type for needsUpdate
};

Calendar.defaultProps = {
  needsUpdate: false,  // Default to false if not provided
};

export default Calendar;