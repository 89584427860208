import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Button, TextField, Box, CircularProgress, Divider } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

// Microsoft icon component
const MicrosoftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0h10v10H10V0z" fill="#f25022"/>
    <path d="M0 0h10v10H0V0z" fill="#00a4ef"/>
    <path d="M10 10h10v10H10V10z" fill="#7fba00"/>
    <path d="M0 10h10v10H0V10z" fill="#ffb900"/>
  </svg>
);

// Google icon component
const GoogleIcon = () => (
  <svg width="20" height="20" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
  </svg>
);

const LoginPage = () => {
  const { login, microsoftSignIn, googleSignIn } = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [microsoftLoading, setMicrosoftLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setLoading(true);

    try {
      await login(email, password);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
      try {
        setGoogleLoading(true);
        setError('');
        await googleSignIn();
      } catch (err) {
        setError(err.message);
        console.error('Google sign in error:', err);
      } finally {
        setGoogleLoading(false);
      }
    };


  const handleMicrosoftSignIn = async () => {
    try {
      setMicrosoftLoading(true);
      setError('');
      await microsoftSignIn();
    } catch (err) {
      setError(err.message);
      console.error('Microsoft sign in error:', err);
    } finally {
      setMicrosoftLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={6} md={3}>
        <Box textAlign="center" mb={5}>
          <Typography variant="h6" component="div" gutterBottom>
            Welcome to
          </Typography>
          <img
            src="/MD_logo.png"
            alt="Logo"
            style={{ width: '200px', height: 'auto' }}
          />
        </Box>

        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="bold" gutterBottom>
              Login
            </Typography>
            {error && (
              <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                {error}
              </Typography>
            )}

            {/* Google Sign In Button */}
            <Button
              variant="outlined"
              fullWidth
              onClick={handleGoogleSignIn}
              disabled={googleLoading}
              sx={{
                mb: 2,
                height: '48px',
                textTransform: 'none',
                borderColor: '#8C8C8C',
                color: '#000000',
                '&:hover': {
                  borderColor: '#DADCE0',
                  backgroundColor: '#f8f9fa'
                }
              }}
              startIcon={googleLoading ? null : <GoogleIcon />}
            >
              {googleLoading ? <CircularProgress size={24} /> : 'Sign in with Google'}
            </Button>

            {/* Microsoft Sign In Button */}
            <Button
              variant="outlined"
              fullWidth
              onClick={handleMicrosoftSignIn}
              disabled={microsoftLoading}
              sx={{
                height: '48px',
                textTransform: 'none',
                borderColor: '#8C8C8C',
                color: '#000000',
                '&:hover': {
                  borderColor: '#8C8C8C',
                  backgroundColor: '#f8f9fa'
                }
              }}
              startIcon={microsoftLoading ? null : <MicrosoftIcon />}
            >
              {microsoftLoading ? <CircularProgress size={24} /> : 'Sign in with Microsoft'}
            </Button>

            {/* Divider */}
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 3, mb: 2 }}>
              <Divider sx={{ flex: 1 }} />
              <Typography variant="body2" sx={{ mx: 2, color: 'text.secondary' }}>
                or
              </Typography>
              <Divider sx={{ flex: 1 }} />
            </Box>

            <form onSubmit={handleLogin}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                margin="normal"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
              />
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
              />
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  fullWidth
                  disabled={loading}
                  sx={{ height: '48px' }}
                >
                  {loading ? <CircularProgress size={24} /> : 'Login'}
                </Button>
              </Box>
              <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                  <Link to="/register" style={{ color: '#6200ee', textDecoration: 'none' }}>
                    Create Account
                  </Link>
                </Typography>
                <Typography variant="body2">
                  <Link to="/forgot-password" style={{ color: '#6200ee', textDecoration: 'none' }}>
                    Forgot password?
                  </Link>
                </Typography>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LoginPage;