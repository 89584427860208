import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Container,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Cookies from 'js-cookie';

const SubscribePage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubscribe = async () => {
    setLoading(true);

    try {
      // Read the _fbp and _fbc cookies set by the Facebook Pixel
      const fbpCookie = Cookies.get('_fbp') || null;
      const fbcCookie = Cookies.get('_fbc') || null;

      // Include the cookies in the request payload
      const response = await axiosInstance.post('/api/user/subscribe', {
        fbp: fbpCookie,
        fbc: fbcCookie,
      });
      const data = response.data;

      if (response.status === 200) {
        // Redirect the user to Stripe Checkout
        window.location.href = data.checkout_url;
      } else if (response.status === 401 || response.status === 403) {
        alert('Please log in to subscribe.');
        navigate('/login');
      } else {
        console.error('Subscription error:', data.message || 'An error occurred');
        alert(data.message || 'An error occurred during subscription.');
      }
    } catch (error) {
      console.error('Error during the subscription process:', error);
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="md" style={{ paddingTop: '50px', paddingBottom: '150px' }}>
      <Typography variant="h3" align="center" style={{ paddingTop: '50px', paddingBottom: '10px' }} gutterBottom>
        Upgrade to Premium
      </Typography>
      <Typography variant="h6" align="center" color="textSecondary" style={{ paddingBottom: '20px' }} gutterBottom>
        Unlock all features with our premium plan
      </Typography>
      <Grid container spacing={4} justifyContent="center" alignItems="flex-start">
        {/* Free Plan */}
        <Grid item xs={12} sm={8} md={5}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h5" component="div" align="center" gutterBottom>
                Free
              </Typography>
              <Typography variant="h4" component="div" align="center" gutterBottom>
                $0
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Up to 3 projects" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Up to 5 tasks per project" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="disabled" />
                  </ListItemIcon>
                  <ListItemText primary="Email Support" />
                </ListItem>
              </List>
            </CardContent>
          </Card>
        </Grid>

        {/* Premium Plan */}
        <Grid item xs={12} sm={8} md={5}>
          <Card elevation={4}>
            <CardContent>
              <Typography variant="h5" component="div" align="center" gutterBottom>
                Premium
              </Typography>
              <Typography variant="h4" component="div" align="center" gutterBottom>
                £4.99/$6.49pm
              </Typography>
              <List>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Unlimited projects" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Unlimited tasks per project" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Analytics (coming soon)" />
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <CheckIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary="Prioritized feedback" />
                </ListItem>
              </List>

              {/* Added "30 day free trial" text here */}
              <Typography variant="body1" align="center" gutterBottom style={{ marginTop: '24px' }}>
                30 day free trial
              </Typography>

              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={handleSubscribe}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Subscribe Now'}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SubscribePage;
