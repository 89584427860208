import React, { useState } from 'react';
import { Box, IconButton, Popover, MenuItem, Dialog, Typography, Badge } from '@mui/material';
import Notifications from './Notifications';
import FeedbackForm from './FeedbackForm';
import CalendarSettings from './CalendarSettings';

function FloatingSettingsMenu({ minTime, maxTime, onMinTimeChange, onMaxTimeChange }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);
  const [dialogTitle, setDialogTitle] = useState('');
  const [hasOpenedMenu, setHasOpenedMenu] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    if (!hasOpenedMenu) {
      setHasOpenedMenu(true); // Hide badge after first click
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenDialog = (content, title) => {
    setDialogContent(content);
    setDialogTitle(title);
    setOpenDialog(true);
    handleCloseMenu();
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        sx={{
          position: 'absolute',
          bottom: 16,
          right: 16,
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
        }}
      >
        <Badge
          color="error"
          variant="dot"
          invisible={hasOpenedMenu}
        >
          <img
            src={`${process.env.PUBLIC_URL}/favicon.ico`}
            alt="icon"
            style={{ width: 38, height: 38 }}
          />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={() => handleOpenDialog(<Notifications />, 'Notifications')}>
          Notifications
        </MenuItem>
        <MenuItem
          onClick={() =>
            handleOpenDialog(
              <CalendarSettings
                minTime={minTime}
                maxTime={maxTime}
                onMinTimeChange={onMinTimeChange}
                onMaxTimeChange={onMaxTimeChange}
                onClose={handleCloseDialog}
              />,
              'Calendar Settings'
            )
          }
        >
          Calendar Settings
        </MenuItem>
        <MenuItem onClick={() => handleOpenDialog(<FeedbackForm />, 'Submit Feedback')}>
          Submit Feedback
        </MenuItem>
      </Popover>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <Box sx={{ p: 3 }}>
          <Typography variant="h6" gutterBottom>
            {dialogTitle}
          </Typography>
          {dialogContent}
        </Box>
      </Dialog>
    </>
  );
}

export default FloatingSettingsMenu;
