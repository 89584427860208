import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { Grid, Card, CardContent, Typography, Button, TextField, Box } from '@mui/material';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrors, setPasswordErrors] = useState({
    minLength: false,
    number: false,
    upperCase: false,
    lowerCase: false,
    specialChar: false,
  });
  const navigate = useNavigate();
  const { token } = useParams();

  useEffect(() => {
    setPasswordErrors(checkPasswordStrength(password));
  }, [password]);

  const checkPasswordStrength = (password) => {
    return {
      minLength: password.length >= 8,
      number: /[0-9]/.test(password),
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      specialChar: /[!@#$%^&*]/.test(password),
    };
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();

    const errors = checkPasswordStrength(password);
    if (!errors.minLength || !errors.number || !errors.upperCase || !errors.lowerCase || !errors.specialChar) {
      alert('Your password does not meet all the requirements.');
      setPasswordErrors(errors);
      return;
    }

    if (password !== confirmPassword) {
      alert('Passwords do not match.');
      return;
    }

    try {
      const response = await axiosInstance.post(`/api/user/reset-password/${token}`, {
        password,
      });
      alert('Your password has been reset successfully.');
      navigate('/login');
    } catch (error) {
      alert('Failed to reset password. Please try again.');
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="bold" gutterBottom>
              Reset Your Password
            </Typography>
            <form onSubmit={handlePasswordReset}>
              <TextField
                label="New Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <TextField
                label="Confirm Password"
                variant="outlined"
                fullWidth
                margin="normal"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <Box mt={2} mb={2}>
                <Typography variant="body2">
                  Password must:
                  <ul>
                    <li className={passwordErrors.minLength ? 'valid' : ''}>Be at least 8 characters long.</li>
                    <li className={passwordErrors.number ? 'valid' : ''}>Contain at least one number.</li>
                    <li className={passwordErrors.upperCase ? 'valid' : ''}>Contain at least one uppercase letter.</li>
                    <li className={passwordErrors.lowerCase ? 'valid' : ''}>Contain at least one lowercase letter.</li>
                    <li className={passwordErrors.specialChar ? 'valid' : ''}>Contain at least one special character (!@#$%^&*).</li>
                  </ul>
                </Typography>
              </Box>
              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit" fullWidth>
                  Reset Password
                </Button>
              </Box>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;