import React from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import TimerIcon from '@mui/icons-material/Timer';
import Layout from '../components/Layout';

function HabitsPage() {
  return (
    <Layout>
      <Grid container spacing={3} style={{ paddingTop: '24px' }}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" style={{ marginBottom: '16px' }}>
            Habits 🌱
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card sx={{
            maxWidth: '800px',
            margin: '0 auto',
            borderRadius: '16px',
            boxShadow: 3,
          }}>
            <CardContent sx={{
              padding: '48px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}>
              <TimerIcon sx={{
                fontSize: 48,
                color: 'text.secondary',
                marginBottom: 2
              }} />
              <Typography variant="h2" sx={{
                fontSize: '2rem',
                fontWeight: 600,
                marginBottom: 2
              }}>
                Coming Soon
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{
                maxWidth: '500px',
                marginBottom: 3
              }}>
                We're working on something special to help you build and track meaningful habits.
              </Typography>
              <Box sx={{
                textAlign: 'left',
                width: '100%',
                maxWidth: '500px'
              }}>
                {[

                ].map((item, index) => (
                  <Box key={index} sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: 1.5
                  }}>
                    <Box sx={{
                      width: 8,
                      height: 8,
                      backgroundColor: item.color,
                      borderRadius: '50%',
                      marginRight: 2
                    }} />
                    <Typography variant="body1" color="text.secondary">
                      {item.text}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default HabitsPage;