import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: 'https://server.meetdoris.com',
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
  },
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

axiosInstance.interceptors.request.use(
  (config) => {
    try {
      const csrfToken = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrf_access_token='))
        ?.split('=')[1];

      if (csrfToken) {
        config.headers['X-CSRF-TOKEN'] = csrfToken;
      }
    } catch (error) {
      console.error('Error retrieving tokens:', error);
    }
    return config;
  },
  (error) => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Handle token refresh
    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => axiosInstance(originalRequest))
          .catch((err) => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const response = await axiosInstance.post('/api/user/refresh_token');
        processQueue(null);
        isRefreshing = false;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError);
        isRefreshing = false;
        console.warn('Token refresh failed, redirecting to login');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }

    // Other error handling
    if (error.response) {
      console.error('API error response:', {
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      });

      if (error.response.status === 403) {
        console.warn('Forbidden! You don\'t have permission for this action.');
      } else if (error.response.status === 500) {
        console.error('Server error! Please try again later.');
      }
    } else if (error.request) {
      console.error('No response from API:', error.request);
    } else {
      console.error('Error setting up the API request:', error.message);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;