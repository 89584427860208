import React, { useState } from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ProjectsPage from './pages/ProjectsPage';
import LoginPage from './pages/Login';
import Register from './pages/Register';
import VerifyPage from './pages/Verify';
import Settings from './pages/Settings';
import HabitsPage from './pages/HabitsPage';
import ForgotPasswordPage from './pages/ForgotPassword';
import SubscribePage from './pages/Subscribe';
import ResetPasswordPage from './pages/ResetPassword';
import SubscribeSuccessPage from './pages/SubscribeSuccess';
import SubscribeCancelPage from './pages/SubscribeCancel';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './contexts/AuthContext';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    setDarkMode((prevMode) => !prevMode);
  };

  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public routes */}
          <Route path="/" element={
            <ProtectedRoute>
              <Navigate to="/calendar" replace />
            </ProtectedRoute>
          } />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} />

          {/* Protected routes */}
          <Route path="/verify" element={
            <ProtectedRoute>
              <VerifyPage />
            </ProtectedRoute>
          } />
          <Route path="/subscribe" element={
            <ProtectedRoute>
              <SubscribePage />
            </ProtectedRoute>
          } />
          <Route path="/calendar" element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          } />
          <Route path="/habits" element={
            <ProtectedRoute>
              <HabitsPage />
            </ProtectedRoute>
          } />
          <Route path="/projects" element={
            <ProtectedRoute>
              <ProjectsPage />
            </ProtectedRoute>
          } />
          <Route path="/settings" element={
            <ProtectedRoute>
              <Settings toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
            </ProtectedRoute>
          } />

          {/* Subscription success and cancel routes */}
          <Route path="/subscribe/success" element={<SubscribeSuccessPage />} />
          <Route path="/subscribe/cancel" element={<SubscribeCancelPage />} />
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;