import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../api/axiosInstance';
import { Grid, Card, CardContent, Typography, Button, TextField, Box, CircularProgress } from '@mui/material';

const VerifyPage = () => {
  const [code, setCode] = useState(Array(6).fill(''));
  const inputsRef = useRef([...Array(6)].map(() => React.createRef()));
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { userEmail } = auth;
  const [resendMessage, setResendMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleInputChange = (value, index) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);
    if (value && index < 5) {
      inputsRef.current[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1].current.focus();
    }
  };

  const handleResendVerification = async () => {
    try {
      const response = await axiosInstance.post('/api/user/resend_verification', {
        email: userEmail,
      });
      setResendMessage(response.data.message || 'Verification email resent. Please check your inbox.');
    } catch (error) {
      setResendMessage(`An error occurred while trying to resend the verification email: ${error.message}`);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      const verifyResponse = await axiosInstance.post('/api/user/verify_code', {
        email: userEmail,
        code: code.join(''),
      });

      if (verifyResponse.status === 200) {
        navigate('/subscribe'); // After successful verification, navigate to subscription page
      } else {
        setErrorMessage(verifyResponse.data.message || 'Verification failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('An error occurred during verification. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <Grid item xs={12} sm={8} md={4}>
        <Card>
          <CardContent>
            <Typography variant="h5" component="div" fontWeight="bold" gutterBottom>
              Verify Your Email
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box display="flex" justifyContent="center" mb={2}>
                {code.map((digit, index) => (
                  <TextField
                    key={index}
                    inputRef={inputsRef.current[index]}
                    value={digit}
                    onChange={(e) => handleInputChange(e.target.value, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                    sx={{ width: 40, mx: 0.5 }}
                  />
                ))}
              </Box>
              {errorMessage && (
                <Typography color="error" variant="body2" gutterBottom>
                  {errorMessage}
                </Typography>
              )}
              <Box mt={2}>
                <Button variant="contained" color="secondary" type="submit" fullWidth disabled={loading}>
                  {loading ? <CircularProgress size={24} /> : 'Verify'}
                </Button>
              </Box>
              <Box mt={2} textAlign="center">
                <Typography variant="body2">
                  Didn't receive the code?{' '}
                  <span onClick={handleResendVerification} style={{ color: 'blue', cursor: 'pointer' }}>
                    Resend
                  </span>
                </Typography>
              </Box>
              {resendMessage && (
                <Typography variant="body2" color="textSecondary" mt={2}>
                  {resendMessage}
                </Typography>
              )}
              <Typography variant="body2" color="textSecondary" mt={2} textAlign="center">
                If you're not automatically redirected after submission, try refreshing.
              </Typography>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default VerifyPage;