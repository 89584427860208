import React, { useState } from 'react';
import { Button, TextField, Grid, Box, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@mui/material';
import axiosInstance from '../api/axiosInstance';

function AddCustomEventForm({ onClose, refreshEvents }) {
  const [eventTitle, setEventTitle] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Basic validation
    if (!eventTitle || !startTime || !endTime) {
      setErrorMessage('All fields are required');
      setIsErrorModalOpen(true);
      return;
    }

    // Ensure end time is after start time
    if (new Date(endTime) <= new Date(startTime)) {
      setErrorMessage('End time must be after start time');
      setIsErrorModalOpen(true);
      return;
    }

    try {
      // Send the event data to the backend API
      await axiosInstance.post('/api/add-custom-event', {
        title: eventTitle,
        start_time: startTime,
        end_time: endTime,
      });

      // If successful, refresh the list of events and close the form
      if (refreshEvents) {
        refreshEvents();
      }

      if (onClose) {
        onClose();
      }
    } catch (error) {
      setErrorMessage('Error adding the event. Please try again.');
      setIsErrorModalOpen(true);
    }
  };

  const handleCloseErrorModal = () => {
    setIsErrorModalOpen(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Event Title"
            variant="outlined"
            fullWidth
            value={eventTitle}
            onChange={(e) => setEventTitle(e.target.value)}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Start Time"
            variant="outlined"
            fullWidth
            type="datetime-local"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="End Time"
            variant="outlined"
            fullWidth
            type="datetime-local"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Add Event
          </Button>
        </Grid>
      </Grid>

      {/* Error Modal */}
      <Dialog open={isErrorModalOpen} onClose={handleCloseErrorModal}>
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseErrorModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}

export default AddCustomEventForm;
