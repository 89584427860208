import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CircularProgress, Box, Button } from '@mui/material';
import Layout from '../components/Layout';
import axios from 'axios';

const SubscribeSuccessPage = () => {
  const location = useLocation();  // Use useLocation to get the session_id from the URL
  const [loading, setLoading] = useState(true);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [error, setError] = useState('');

  // Function to extract session_id from URL query params
  const getSessionIdFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('session_id');
  };

  useEffect(() => {
    const sessionId = getSessionIdFromUrl();
    if (sessionId) {
      // Call your backend to fetch Stripe session details
      fetchSubscriptionDetails(sessionId);
    } else {
      setError('No session ID found in the URL.');
      setLoading(false);
    }
  }, [location.search]);

  const fetchSubscriptionDetails = async (sessionId) => {
    try {
      setLoading(true);
      // Call your backend API to get session details
      const response = await axios.get(`/api/subscription/success/${sessionId}`);
      setSubscriptionDetails(response.data);
    } catch (err) {
      setError('Failed to retrieve subscription details.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Subscription Success
              </Typography>
              {loading && <CircularProgress />}
              {error && (
                <Typography color="error" variant="body2" gutterBottom>
                  {error}
                </Typography>
              )}
              {!loading && !error && subscriptionDetails && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Thank you for subscribing! Your subscription details are as follows:
                  </Typography>
                  <Typography variant="body2">
                    <strong>Subscription ID:</strong> {subscriptionDetails.id}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {subscriptionDetails.status}
                  </Typography>
                  <Box mt={2}>
                    <Button variant="contained" color="primary" href="/calendar">
                      Go to Calendar
                    </Button>
                  </Box>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SubscribeSuccessPage;