import React, { useState, useEffect } from 'react';
import {
  CircularProgress,
  LinearProgress,
  Grid,
  Card,
  CardContent,
  Typography,
  Modal,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Divider,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useNavigate } from 'react-router-dom';
import Layout from '../components/Layout';
import ProjectForm from '../components/ProjectForm';
import axiosInstance from '../api/axiosInstance';
import { useAuth } from '../contexts/AuthContext';

const calculateCompletionPercentage = (tasks) => {
  const totalMinutes = tasks.reduce(
    (total, task) => total + task.estimated_hours * 60 + task.estimated_minutes,
    0
  );
  const completedMinutes = tasks.reduce(
    (completed, task) =>
      task.completed
        ? completed + task.estimated_hours * 60 + task.estimated_minutes
        : completed,
    0
  );
  return totalMinutes > 0 ? (completedMinutes / totalMinutes) * 100 : 0;
};

function ProjectsPage() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [editProject, setEditProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);

  const MAX_FREE_PROJECTS = 3;

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEditProject(null);
  };

  const handleEditProject = async (project) => {
    try {
      const response = await axiosInstance.get(`/api/projects/${project.id}`);
      setEditProject(response.data);
      setOpen(true);
    } catch (err) {
      console.error('Failed to fetch project details:', err);
      setError('Failed to fetch project details.');
    }
  };

  const handleDeleteProject = async () => {
    try {
      await axiosInstance.delete(`/api/projects/${projectToDelete.id}`);
      setProjects((prevProjects) =>
        prevProjects.filter((proj) => proj.id !== projectToDelete.id)
      );
      setDeleteDialogOpen(false);
      setProjectToDelete(null);
    } catch (err) {
      console.error('Failed to delete project:', err);
      setError('Failed to delete project.');
    }
  };

  const handleOpenDeleteDialog = (project) => {
    setProjectToDelete(project);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false);
    setProjectToDelete(null);
  };

  const fetchProjects = async () => {
    try {
      const response = await axiosInstance.get('/api/projects');
      setProjects(response.data.projects);
      setLoading(false);
    } catch (err) {
      setError(`Failed to fetch projects. Error: ${err.message}`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  if (loading) {
    return (
      <Layout>
        <CircularProgress />
        <Typography>Loading projects...</Typography>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <Typography>{error}</Typography>
      </Layout>
    );
  }

  const currentProjects = projects.filter((project) => !project.completed);
  const completedProjects = projects.filter((project) => project.completed);

  const renderAddProjectCard = () => {
    if (!auth.isSubscribed && currentProjects.length >= MAX_FREE_PROJECTS) {
      return (
        <Card
          sx={{
            borderRadius: '16px',
            boxShadow: 3,
            cursor: 'pointer',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '300px',
          }}
          onClick={() => navigate('/subscribe')}
        >
          <Typography variant="h6" color="textSecondary" sx={{ marginTop: '8px' }}>
            Project limit reached
          </Typography>
          <Button variant="contained" color="primary" sx={{ marginTop: '16px' }}>
            Upgrade now
          </Button>
        </Card>
      );
    }

    return (
      <Card
          sx={{
            borderRadius: '16px',
            boxShadow: 3,
            cursor: 'pointer',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '300px',
            backgroundColor: 'grey.50',
          }}
          onClick={handleOpen}
        >
          <AddIcon fontSize="large" sx={{ color: 'grey.800' }} />
          <Typography
            variant="h6"
            sx={{
              marginTop: '8px',
              color: 'grey.600',
              fontSize: projects.length === 0 ? '1.5rem' : '1.25rem',
              fontWeight: projects.length === 0 ? 400 : 400,
            }}
          >
            {projects.length === 0 ? 'Create your first project!' : 'Add Project 📁'}
          </Typography>
        </Card>
      );
    };

  return (
    <Layout>
      <Grid container spacing={3} style={{ paddingTop: '24px' }}>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Typography variant="h4" style={{ marginBottom: '16px' }}>
            {projects.length === 0 ? 'Welcome!👋 ' : 'Projects 📁'}
          </Typography>
          {!auth.isSubscribed && projects.length > 0 && (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1" sx={{ marginRight: '16px' }}>
                {`${currentProjects.length}/${MAX_FREE_PROJECTS}`}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/subscribe')}
              >
                Upgrade
              </Button>
            </Box>
          )}
        </Grid>

        {currentProjects.map((project) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            lg={3}
            key={project.id}
            style={{ display: 'flex', flexDirection: 'column' }}
          >
            <Card
              sx={{
                borderRadius: '16px',
                boxShadow: 3,
                position: 'relative',
                overflow: 'hidden',
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <CardContent sx={{ padding: '16px', flexGrow: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                  <Box
                    sx={{
                      width: '12px',
                      height: '12px',
                      backgroundColor: project.color,
                      borderRadius: '50%',
                      marginRight: '8px',
                    }}
                  />
                  <Typography variant="h5" component="div">
                    {project.name}
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '16px' }}>
                  <Typography variant="body2" color="textSecondary">
                    Deadline: {new Date(project.deadline).toLocaleDateString()}
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: '12px' }}>
                  <LinearProgress
                    variant="determinate"
                    value={calculateCompletionPercentage(project.tasks)}
                    sx={{ height: '8px', borderRadius: '4px' }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '4px' }}>
                    <Typography variant="body2" color="textSecondary">
                      Progress
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {`${Math.round(calculateCompletionPercentage(project.tasks))}%`}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                  <AssignmentIcon fontSize="small" sx={{ marginRight: '4px' }} />
                  <Typography variant="body2" color="textSecondary">
                    {project.tasks.length} Tasks
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleEditProject(project)}
                >
                  View
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={() => handleOpenDeleteDialog(project)}
                >
                  Delete
                </Button>
              </Box>
            </Card>
          </Grid>
        ))}

        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          {renderAddProjectCard()}
        </Grid>

        {completedProjects.length > 0 && (
          <>
            <Grid item xs={12}>
              <Typography variant="h4" style={{ marginTop: '24px', marginBottom: '16px' }}>
                Completed Projects
              </Typography>
            </Grid>
            {completedProjects.map((project) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                key={project.id}
                style={{ display: 'flex', flexDirection: 'column' }}
              >
                <Card
                  sx={{
                    borderRadius: '16px',
                    boxShadow: 3,
                    position: 'relative',
                    overflow: 'hidden',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <CardContent sx={{ padding: '16px', flexGrow: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                      <Box
                        sx={{
                          width: '12px',
                          height: '12px',
                          backgroundColor: project.color,
                          borderRadius: '50%',
                          marginRight: '8px',
                        }}
                      />
                      <Typography variant="h5" component="div">
                        {project.name}
                      </Typography>
                    </Box>
                    <Box sx={{ marginBottom: '16px' }}>
                      <Typography variant="body2" color="textSecondary">
                        Completed on: {new Date(project.deadline).toLocaleDateString()}
                      </Typography>
                    </Box>
                  </CardContent>
                  <Divider />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleEditProject(project)}
                    >
                      View
                    </Button>
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => handleOpenDeleteDialog(project)}
                    >
                      Delete
                    </Button>
                  </Box>
                </Card>
              </Grid>
            ))}
          </>
        )}
      </Grid>

      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <Typography variant="h6" gutterBottom>
            {editProject ? 'Edit Project' : 'Add a New Project'}
          </Typography>
          <ProjectForm onClose={handleClose} refreshProjects={fetchProjects} project={editProject} />
        </Box>
      </Modal>

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this project? This action is irreversible.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteProject} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
}

export default ProjectsPage;