import React, { useState } from 'react';
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Checkbox,
  FormControlLabel,
  Button,
  CircularProgress,
  Typography, // Import Typography for the title and additional text
  Box, // Import Box for padding
} from '@mui/material';
import axiosInstance from '../api/axiosInstance';

const timezones = [
  'Pacific/Honolulu',
  'America/Anchorage',
  'America/Los_Angeles',
  'America/Denver',
  'America/Chicago',
  'America/New_York',
  'America/Sao_Paulo',
  'Europe/London',
  'Europe/Paris',
  'Europe/Berlin',
  'Europe/Madrid',
  'Europe/Istanbul',
  'Asia/Dubai',
  'Asia/Kolkata',
  'Asia/Shanghai',
  'Asia/Tokyo',
  'Australia/Sydney',
];

function Step1WorkSetup({
  completeOnboarding, // Accept completeOnboarding function as a prop
  setWorkStartTime,
  setWorkEndTime,
  setTimezone,
  setWorkdays,
  workStartTime,
  workEndTime,
  timezone,
  workdays,
}) {
  const [isSaving, setIsSaving] = useState(false);

  const handleWorkdayChange = (event) => {
    setWorkdays((prevWorkdays) => ({
      ...prevWorkdays,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSaving(true);

    const workdaysFormatted = Object.keys(workdays).filter(day => workdays[day]);

    const settingsData = {
      workStartTime,
      workEndTime,
      timezone,
      workdays: workdaysFormatted,
    };

    try {
      await axiosInstance.post('/api/user/settings', settingsData);
      await completeOnboarding(); // Complete the onboarding process after saving
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        {/* Modal Title */}
        <Grid item xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Getting Started
          </Typography>
        </Grid>

        {/* Work Start Time with Tooltip */}
        <Grid item xs={12}>
          <Tooltip title="The time you typically start working each day." arrow>
            <TextField
              label="Work Start Time"
              variant="outlined"
              fullWidth
              type="time"
              value={workStartTime}
              onChange={(e) => setWorkStartTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Tooltip>
        </Grid>

        {/* Work End Time with Tooltip */}
        <Grid item xs={12}>
          <Tooltip title="The time you usually finish working each day." arrow>
            <TextField
              label="Work End Time"
              variant="outlined"
              fullWidth
              type="time"
              value={workEndTime}
              onChange={(e) => setWorkEndTime(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Tooltip>
        </Grid>

        {/* Timezone Selection */}
        <Grid item xs={12}>
          <FormControl fullWidth variant="outlined" required>
            <InputLabel id="timezone-label">Time Zone</InputLabel>
            <Select
              labelId="timezone-label"
              id="timezone-select"
              value={timezone}
              onChange={(e) => setTimezone(e.target.value)}
              label="Time Zone"
            >
              <MenuItem value="">
                <em>Select Time Zone</em>
              </MenuItem>
              {timezones.map((zone) => (
                <MenuItem key={zone} value={zone}>
                  {zone}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Workdays Checkbox */}
        <Grid item xs={12}>
          <Grid container>
            {Object.keys(workdays).map((day) => (
              <Grid item xs={6} key={day}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={workdays[day]}
                      onChange={handleWorkdayChange}
                      name={day}
                      color="primary"
                    />
                  }
                  label={day}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Informational Text */}
        <Grid item xs={12}>
          <Box mt={2} mb={2}>
            <Typography variant="body1" align="center" gutterBottom>
              Next, connect to your calendar in the settings menu!
            </Typography>
          </Box>
        </Grid>

        {/* Submit Button */}
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSaving}
          >
            {isSaving ? <CircularProgress size={24} /> : 'Save & Continue'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}

export default Step1WorkSetup;
