import React, { useState, useEffect } from 'react';
import {
  Container,
  Paper,
  Tabs,
  Tab,
  Typography,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Alert,
  Snackbar,
  Stack,
  IconButton,
} from '@mui/material';
import {
  Google as GoogleIcon,
  CalendarMonth as CalendarIcon,
  AccessTime as TimeIcon,
  AccountCircle as AccountIcon,
  Upload as UploadIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import axiosInstance from '../api/axiosInstance';
import Layout from '../components/Layout';

// Microsoft icon component
const MicrosoftIcon = () => (
  <svg width="20" height="20" viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 0h10v10H10V0z" fill="#f25022"/>
    <path d="M0 0h10v10H0V0z" fill="#00a4ef"/>
    <path d="M10 10h10v10H10V10z" fill="#7fba00"/>
    <path d="M0 10h10v10H0V10z" fill="#ffb900"/>
  </svg>
);

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({
    workStartTime: '',
    workEndTime: '',
    timezone: '',
    workdays: {
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    }
  });

  const [calendarConnections, setCalendarConnections] = useState({
    google: false,
    outlook: false
  });

  const [dialogs, setDialogs] = useState({
    purge: false,
    moodle: false
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const [moodleUrl, setMoodleUrl] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const timezones = [
    "Pacific/Honolulu",
    "America/Los_Angeles",
    "America/New_York",
    "Europe/London",
    "Europe/Paris",
    "Asia/Dubai",
    "Asia/Tokyo",
    "Australia/Sydney"
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [settingsRes, calendarRes] = await Promise.all([
          axiosInstance.get('/api/user/settings'),
          axiosInstance.get('/api/calendar-status')
        ]);

        setSettings({
          ...settingsRes.data,
          workdays: {
            Monday: settingsRes.data.workdays.includes('Monday'),
            Tuesday: settingsRes.data.workdays.includes('Tuesday'),
            Wednesday: settingsRes.data.workdays.includes('Wednesday'),
            Thursday: settingsRes.data.workdays.includes('Thursday'),
            Friday: settingsRes.data.workdays.includes('Friday'),
            Saturday: settingsRes.data.workdays.includes('Saturday'),
            Sunday: settingsRes.data.workdays.includes('Sunday'),
          }
        });

        setCalendarConnections({
          google: calendarRes.data.isGoogleLinked,
          outlook: calendarRes.data.isOutlookLinked
        });
      } catch (error) {
        showSnackbar('Failed to load settings', 'error');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const showSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCalendarAction = async (type, action) => {
    try {
      if (action === 'connect') {
        const response = await axiosInstance.get(`/api/${type}/connect-calendar`);
        if (response.data.authorization_url) {
          window.location.href = response.data.authorization_url;
        }
      } else {
        await axiosInstance.post(`/api/${type}/unlink-calendar`);
        setCalendarConnections(prev => ({
          ...prev,
          [type]: false
        }));
        showSnackbar(`Successfully unlinked ${type} calendar`);
      }
    } catch (error) {
      showSnackbar(`Failed to ${action} ${type} calendar`, 'error');
    }
  };

  const handleSaveSettings = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      const workdaysArray = Object.entries(settings.workdays)
        .filter(([_, isSelected]) => isSelected)
        .map(([day]) => day);

      await axiosInstance.post('/api/user/settings', {
        ...settings,
        workdays: workdaysArray
      });
      showSnackbar('Settings saved successfully');
    } catch (error) {
      showSnackbar('Failed to save settings', 'error');
    } finally {
      setIsSaving(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        await axiosInstance.post('/api/upload-calendar', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        showSnackbar('Calendar file uploaded successfully');
      } catch (error) {
        showSnackbar('Failed to upload calendar file', 'error');
      }
    }
  };

  const handleMoodleSubmit = async () => {
    try {
      await axiosInstance.post('/api/upload-calendar-url', { url: moodleUrl });
      setDialogs({ ...dialogs, moodle: false });
      setMoodleUrl('');
      showSnackbar('Moodle calendar connected successfully');
    } catch (error) {
      showSnackbar('Failed to connect Moodle calendar', 'error');
    }
  };

  const handlePurgeData = async () => {
    try {
      await axiosInstance.post('/api/user/settings/purge');
      setDialogs({ ...dialogs, purge: false });
      showSnackbar('All data purged successfully');
    } catch (error) {
      showSnackbar('Failed to purge data', 'error');
    }
  };

  if (loading) {
    return (
      <Layout>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
          <CircularProgress />
        </Box>
      </Layout>
    );
  }

  return (
    <Layout>
      <Container maxWidth="md" sx={{ py: 4 }}>
        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              aria-label="settings tabs"
              centered
            >
              <Tab icon={<CalendarIcon />} label="Calendars" />
              <Tab icon={<TimeIcon />} label="Work Hours" />
              <Tab icon={<AccountIcon />} label="Account" />
            </Tabs>
          </Box>

          <TabPanel value={activeTab} index={0}>
            <Stack spacing={3}>
              <Typography variant="h6" gutterBottom>
                Calendar Connections
              </Typography>

              <Button
                variant="outlined"
                startIcon={<GoogleIcon />}
                disabled
                fullWidth
                sx={{
                  height: 48,
                  backgroundColor: '#fff',
                  color: '#9e9e9e',
                  borderColor: '#e0e0e0',
                  '&.Mui-disabled': {
                    color: '#9e9e9e',
                    borderColor: '#e0e0e0',
                    backgroundColor: '#fff',
                  },
                  '&:hover': {
                    backgroundColor: '#fff',
                    borderColor: '#e0e0e0',
                  },
                }}
              >
                Connect Google Calendar (coming soon)
              </Button>

              <Button
                variant="outlined"
                startIcon={<MicrosoftIcon />}
                onClick={() => handleCalendarAction('outlook', calendarConnections.outlook ? 'unlink' : 'connect')}
                fullWidth
                sx={{
                  height: 48,
                  backgroundColor: calendarConnections.outlook ? '#fff' : '#f0f9ff',
                  color: '#0078D4',
                  borderColor: '#0078D4',
                  '&:hover': {
                    backgroundColor: calendarConnections.outlook ? '#f8f9fa' : '#e1f3ff',
                    borderColor: '#0078D4',
                  },
                }}
              >
                {calendarConnections.outlook ? 'Unlink Outlook Calendar' : 'Connect Outlook Calendar'}
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setDialogs({ ...dialogs, moodle: true })}
                fullWidth
                sx={{
                  height: 48,
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                Connect Moodle Calendar
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                component="label"
                startIcon={<UploadIcon />}
                fullWidth
                sx={{
                  height: 48,
                  '&:hover': {
                    backgroundColor: '#f5f5f5',
                  },
                }}
              >
                Upload Calendar File (.ics)
                <input
                  type="file"
                  hidden
                  accept=".ics"
                  onChange={handleFileUpload}
                />
              </Button>
            </Stack>
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <form onSubmit={handleSaveSettings}>
              <Stack spacing={3}>
                <TextField
                  label="Work Start Time"
                  type="time"
                  value={settings.workStartTime}
                  onChange={(e) => setSettings({ ...settings, workStartTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />

                <TextField
                  label="Work End Time"
                  type="time"
                  value={settings.workEndTime}
                  onChange={(e) => setSettings({ ...settings, workEndTime: e.target.value })}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />

                <FormControl fullWidth>
                  <InputLabel>Timezone</InputLabel>
                  <Select
                    value={settings.timezone}
                    label="Timezone"
                    onChange={(e) => setSettings({ ...settings, timezone: e.target.value })}
                  >
                    {timezones.map(zone => (
                      <MenuItem key={zone} value={zone}>{zone}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Work Days
                  </Typography>
                  <FormGroup>
                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2}>
                      {Object.entries(settings.workdays).map(([day, checked]) => (
                        <FormControlLabel
                          key={day}
                          control={
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setSettings({
                                ...settings,
                                workdays: {
                                  ...settings.workdays,
                                  [day]: e.target.checked
                                }
                              })}
                            />
                          }
                          label={day}
                        />
                      ))}
                    </Box>
                  </FormGroup>
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isSaving}
                  fullWidth
                  sx={{ height: 48 }}
                >
                  {isSaving ? 'Saving...' : 'Save Settings'}
                </Button>
              </Stack>
            </form>
          </TabPanel>

          <TabPanel value={activeTab} index={2}>
            <Stack spacing={3}>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                sx={{ height: 48 }}
                onClick={() => {
                  window.open('https://billing.stripe.com/p/login/00g5mhakicN72L6bII', '_blank');
                }}
              >
                Manage Subscription
              </Button>

              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                fullWidth
                sx={{ height: 48 }}
                onClick={() => setDialogs({ ...dialogs, purge: true })}
              >
                Purge All Data
              </Button>
            </Stack>
          </TabPanel>
        </Paper>

        {/* Moodle Dialog */}
        <Dialog
          open={dialogs.moodle}
          onClose={() => setDialogs({ ...dialogs, moodle: false })}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Connect Moodle Calendar</DialogTitle>
          <DialogContent>
            <DialogContentText>
              To connect your Moodle calendar:
              <ol style={{ marginTop: '1rem', paddingLeft: '1.5rem' }}>
                <li>Log in to your Moodle account</li>
                <li>Go to your calendar and select "Export calendar"</li>
                <li>Copy the provided calendar URL</li>
                <li>Paste the URL below and submit</li>
              </ol>
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Moodle Calendar URL"
              type="url"
              fullWidth
              value={moodleUrl}
              onChange={(e) => setMoodleUrl(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogs({ ...dialogs, moodle: false })}>
              Cancel
            </Button>
            <Button onClick={handleMoodleSubmit} disabled={!moodleUrl}>
              Connect
            </Button>
          </DialogActions>
        </Dialog>

        {/* Purge Dialog */}
        <Dialog
          open={dialogs.purge}
          onClose={() => setDialogs({ ...dialogs, purge: false })}
        >
          <DialogTitle>Confirm Data Purge</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to purge all your data? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDialogs({ ...dialogs, purge: false })}>
              Cancel
            </Button>
            <Button onClick={handlePurgeData} color="error">
              Confirm Purge
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar for notifications */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >

      <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            elevation={6}
            variant="filled"
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Layout>
  );
}

export default Settings;