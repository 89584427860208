import React, { useEffect, useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import { Typography, List, ListItem, ListItemText } from '@mui/material';

function Notifications() {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    // Fetch notifications from the API
    const fetchNotifications = async () => {
      try {
        const response = await axiosInstance.get('/api/projects_with_excess_time');
        const projects = response.data.projects;

        const projectNotifications = projects
          .filter((project) => project.total_excess_time > 0)
          .map((project) => ({
            message: `Project "${project.name}" has ${project.total_excess_time} minutes of excess time.`,
            projectId: project.id,
          }));

        setNotifications(projectNotifications);
      } catch (error) {
        console.error('Error fetching project notifications:', error);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <List
      sx={{
        maxHeight: '300px',
        overflow: 'auto',
      }}
    >
      {notifications.length > 0 ? (
        notifications.map((notification, index) => (
          <ListItem key={index}>
            <ListItemText primary={notification.message} />
          </ListItem>
        ))
      ) : (
        <Typography variant="body2" color="textSecondary">
          Welcome to MeetDoris! View the getting started guide{' '}
          <a
            href="https://www.meetdoris.com/help/article/getting-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          !
        </Typography>
      )}
    </List>
  );
}

export default Notifications;
