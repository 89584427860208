import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CircularProgress, Box, Button } from '@mui/material';
import Layout from '../components/Layout';
import axios from 'axios';

const SubscribeCancelPage = () => {
  const location = useLocation();  // Use useLocation to get the session_id from the URL
  const [loading, setLoading] = useState(true);
  const [cancellationDetails, setCancellationDetails] = useState(null);
  const [error, setError] = useState('');

  // Function to extract session_id from URL query params
  const getSessionIdFromUrl = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('session_id');
  };

  useEffect(() => {
    const sessionId = getSessionIdFromUrl();
    if (sessionId) {
      // Call your backend to fetch Stripe cancellation details
      fetchCancellationDetails(sessionId);
    } else {
      setError('No session ID found in the URL.');
      setLoading(false);
    }
  }, [location.search]);

  const fetchCancellationDetails = async (sessionId) => {
    try {
      setLoading(true);
      // Call your backend API to get cancellation details
      const response = await axios.get(`/api/subscription/cancel/${sessionId}`);
      setCancellationDetails(response.data);
    } catch (err) {
      setError('Failed to retrieve cancellation details.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout>
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <Grid item xs={12} sm={8} md={6}>
          <Card>
            <CardContent>
              <Typography variant="h5" component="div" gutterBottom>
                Subscription Cancellation
              </Typography>
              {loading && <CircularProgress />}
              {error && (
                <Typography color="error" variant="body2" gutterBottom>
                  {error}
                </Typography>
              )}
              {!loading && !error && cancellationDetails && (
                <>
                  <Typography variant="body1" gutterBottom>
                    Your subscription has been canceled. Here are the details:
                  </Typography>
                  <Typography variant="body2">
                    <strong>Subscription ID:</strong> {cancellationDetails.id}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {cancellationDetails.status}
                  </Typography>
                  <Box mt={2}>
                    <Button variant="contained" color="primary" href="/pricing">
                      View Pricing Plans
                    </Button>
                  </Box>
                </>
              )}
              {!loading && !error && !cancellationDetails && (
                <Typography variant="body2" gutterBottom>
                  It seems the subscription was canceled, but no further details are available.
                </Typography>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SubscribeCancelPage;