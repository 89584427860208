import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Typography,
  ListItemIcon,
  Divider,
  IconButton,
} from '@mui/material';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// You'll need to import these icons from @mui/icons-material
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

function Sidebar({ isCollapsed, toggleCollapse }) {
  const { auth, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePath = (path) => {
    return location.pathname === path;
  };

  const getListItemStyles = (path) => ({
    marginTop: '16px',
    borderRadius: '8px',
    backgroundColor: isActivePath(path) ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
    '&:hover': {
      backgroundColor: isActivePath(path)
        ? 'rgba(255, 255, 255, 0.25)'
        : 'rgba(255, 255, 255, 0.1)',
    },
  });

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="100vh"
      padding="16px"
      sx={{
        backgroundColor: '#453584',
        paddingRight: isCollapsed ? '8px' : '24px',
        boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.5)',
        transition: 'all 0.3s ease',
        width: isCollapsed ? '80px' : '240px',
        position: 'relative',
      }}
    >
      {/* Collapse Toggle Button */}
      <IconButton
        onClick={toggleCollapse}
        sx={{
          position: 'absolute',
          right: '-20px',
          top: '20px',
          backgroundColor: '#453584',
          color: 'white',
          '&:hover': { backgroundColor: '#56588c' },
          zIndex: 1,
        }}
      >
        {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
      </IconButton>

      {/* Logo Section */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        marginBottom="16px"
        marginTop="16px"
      >
        <img
          src={`${process.env.PUBLIC_URL}/inverselogo.png`}
          alt="Sidebar Logo"
          style={{
            width: isCollapsed ? '40px' : '150px',
            height: 'auto',
            transition: 'width 0.3s ease'
          }}
        />
      </Box>

      <Divider sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', marginBottom: '16px' }} />

      {/* Navigation Links */}
      <Box sx={{ flexGrow: 1 }}>
        <List>
          {[
            { path: '/calendar', icon: 'calendar-day.svg', text: 'Calendar' },
            { path: '/projects', icon: 'bullseye-arrow.svg', text: 'Projects' },
              { path: '/habits', icon: 'habits.svg', text: 'Habits' },
            { path: '/settings', icon: 'settings-sliders.svg', text: 'Settings' },
          ].map((item) => (
            <ListItem
              key={item.path}
              button
              component={Link}
              to={item.path}
              sx={getListItemStyles(item.path)}
            >
              <ListItemIcon>
                <img
                  src={`${process.env.PUBLIC_URL}/interface-icons/${item.icon}`}
                  alt={`${item.text} Icon`}
                  style={{ width: '24px', height: '24px', filter: 'invert(100%)' }}
                />
              </ListItemIcon>
              {!isCollapsed && (
                <ListItemText
                  primary={<Typography sx={{ color: '#ffffff' }}>{item.text}</Typography>}
                />
              )}
            </ListItem>
          ))}
        </List>
      </Box>

      {/* Conditionally Render Upgrade Button */}
      {!auth.isSubscribed && !isCollapsed && (
        <Box mb={2}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            sx={{
              color: '#ffffff',
              borderRadius: '8px',
              mb: 1,
              backgroundColor: isActivePath('/subscribe') ? '#56588c' : undefined,
            }}
            onClick={() => navigate('/subscribe')}
          >
            Upgrade
          </Button>
        </Box>
      )}

      {/* Logout Button */}
      <Box>
        <Button
          className="sidebarButton"
          variant="contained"
          fullWidth
          sx={{
            color: '#ffffff !important',
            backgroundColor: '#7B5DEF',
            borderRadius: '8px',
            minWidth: isCollapsed ? '40px' : 'auto',
            '&:hover': {
              backgroundColor: '#56588c',
            },
          }}
          onClick={async () => {
            try {
              await logout();
              console.log('Logout successful');
            } catch (error) {
              console.error('Error during logout:', error);
            }
          }}
        >
          {isCollapsed ? '←' : 'Logout'}
        </Button>
      </Box>
    </Box>
  );
}

export default Sidebar;