// CalendarToolbar.js
import React from 'react';
import { ButtonGroup, Button, Box, Typography, useTheme } from '@mui/material';

const CalendarToolbar = (toolbar) => {
  const theme = useTheme();
  const { view, views, label } = toolbar;

  const handleNavigate = (action) => {
    toolbar.onNavigate(action);
  };

  const handleViewChange = (newView) => {
    toolbar.onView(newView);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 2,
      }}
    >
      {/* Navigation Buttons */}
      <ButtonGroup>
        <Button
          onClick={() => handleNavigate('TODAY')}
          sx={{
            backgroundColor: theme.palette.grey[50],
            color: 'black',
            textTransform: 'none',
            border: `1px solid ${theme.palette.grey[400]}`, // Outline color
            '&:hover': {
              backgroundColor: theme.palette.grey[400],
            },
          }}
        >
          Today
        </Button>
        <Button
          onClick={() => handleNavigate('PREV')}
          sx={{
            backgroundColor: theme.palette.grey[50],
            color: 'black',
            textTransform: 'none',
            border: `1px solid ${theme.palette.grey[400]}`, // Outline color
            '&:hover': {
              backgroundColor: theme.palette.grey[400],
            },
          }}
        >
          Back
        </Button>
        <Button
          onClick={() => handleNavigate('NEXT')}
          sx={{
            backgroundColor: theme.palette.grey[50],
            color: 'black',
            textTransform: 'none',
            border: `1px solid ${theme.palette.grey[400]}`, // Outline color
            '&:hover': {
              backgroundColor: theme.palette.grey[100],
            },
          }}
        >
          Next
        </Button>
      </ButtonGroup>

      {/* Calendar Label */}
      <Typography variant="h6">{label}</Typography>

      {/* View Buttons */}
      <ButtonGroup>
        {views.map((viewName) => (
          <Button
            key={viewName}
            onClick={() => handleViewChange(viewName)}
            sx={{
              backgroundColor: view === viewName ? 'white' : theme.palette.grey[300],
              color: 'black',
              textTransform: 'none',
              border: `1px solid ${theme.palette.grey[400]}`, // Outline color
              '&:hover': {
                backgroundColor: view === viewName ? 'white' : theme.palette.grey[400],
              },
            }}
          >
            {viewName.charAt(0).toUpperCase() + viewName.slice(1)}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default CalendarToolbar;
