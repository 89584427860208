import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();

  console.log('ProtectedRoute checks:', {
    isLoggedIn: auth.isLoggedIn,
    isEmailVerified: auth.isEmailVerified,
    isCheckingSession: auth.isCheckingSession,
    path: location.pathname,
  });

  if (auth.isCheckingSession) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />  {/* Display spinner while session is loading */}
      </Box>
    );
  }

  // If not logged in, redirect to the login page
  if (!auth.isLoggedIn) {
    console.log('User not logged in or session expired, redirecting to /login...');
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // If email is not verified and user is not on the verification page, redirect to /verify
  if (!auth.isEmailVerified && location.pathname !== '/verify') {
    console.log('Email not verified, redirecting to /verify...');
    return <Navigate to="/verify" state={{ from: location }} replace />;
  }

  // Allow access to the verification page if email is not verified
  if (!auth.isEmailVerified && location.pathname === '/verify') {
    console.log('Email not verified but on /verify page, rendering children...');
    return children;
  }

  // Prevent access to /verify if user is already verified
  if (auth.isEmailVerified && location.pathname === '/verify') {
    console.log('User is already verified, redirecting to /home...');
    return <Navigate to="/calendar" replace />;
  }

  // If all checks pass, render the protected content
  console.log('All conditions met, rendering protected content...');
  return children;
};

export default ProtectedRoute;