import React, { useState } from 'react';
import axiosInstance from '../api/axiosInstance';
import {
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

function FeedbackForm() {
  const [feedbackText, setFeedbackText] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const handleFeedbackChange = (event) => {
    setFeedbackText(event.target.value);
  };

  const handleSubmitFeedback = async () => {
    try {
      await axiosInstance.post('/api/feedback', { feedbackText });
      setModalMessage(
        'Your feedback has been successfully submitted. We appreciate your input!'
      );
      setFeedbackText('');
    } catch (error) {
      if (error.response?.status === 429) {
        setModalMessage(
          'You can only submit feedback once per minute. Please try again later.'
        );
      } else {
        setModalMessage('Failed to submit feedback. Please try again later.');
      }
    } finally {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <TextField
        label="Got suggestions? Enter them here."
        multiline
        rows={4}
        value={feedbackText}
        onChange={handleFeedbackChange}
        variant="outlined"
        placeholder="Got suggestions? Enter them here."
        fullWidth
        sx={{
          '& .MuiInputBase-root': {
            whiteSpace: 'normal',
            wordWrap: 'break-word',
          },
        }}
      />
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSubmitFeedback}
        disabled={!feedbackText.trim()}
        sx={{ mt: 2 }}
        fullWidth
      >
        Submit Feedback
      </Button>

      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle>Feedback Status</DialogTitle>
        <DialogContent>
          <Typography>{modalMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default FeedbackForm;
