import React from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

function CalendarSettings({ minTime, maxTime, onMinTimeChange, onMaxTimeChange, onClose }) {
  return (
    <Box>
      <FormControl fullWidth variant="outlined" size="small" sx={{ mb: 2 }}>
        <InputLabel>Earliest</InputLabel>
        <Select value={minTime} onChange={(e) => onMinTimeChange(e.target.value)} label="Earliest">
          {Array.from({ length: 24 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {i}:00
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth variant="outlined" size="small">
        <InputLabel>Latest</InputLabel>
        <Select value={maxTime} onChange={(e) => onMaxTimeChange(e.target.value)} label="Latest">
          {Array.from({ length: 24 }, (_, i) => (
            <MenuItem key={i} value={i}>
              {i}:00
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Button onClick={onClose} fullWidth variant="contained" sx={{ mt: 2 }}>
        Save
      </Button>
    </Box>
  );
}

export default CalendarSettings;
